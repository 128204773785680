.DefaultProgressBar_progressBar {
  background-color: #d47e5b;
  position: absolute;
}

.DefaultProgressBar_background__horizontal {
  height: 7px;
  top: 0px;
}

.DefaultHandle_handle {
  width: 29px;
  height: 29px;
  border-width: 1px;
  border-style: solid;
  border-color: #d47e5b;
  background-color: #ffffff;
  border-radius: 29px;
  outline: none;
  z-index: 2;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  cursor: grabbing;
}

.DefaultHandle_text {
  position: absolute;
  top: -35px;
  left: -37px;
  right: 0;
  width: 100px;
  text-align: center;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #4f4f4f;
}

.DefaultHandle_handle__horizontal {
  margin-left: -15.5px;
  top: -11.5px;
}

.DefaultHandle_handle__horizontal:before {
  top: 7px;
  height: 10px;
  width: 1px;
  left: 10px;
}

.DefaultHandle_handle__horizontal:after {
  top: 7px;
  height: 10px;
  width: 1px;
  left: 13px;
}

.DefaultHandle_handle__disabled {
  border-color: #dbdbdb;
}

.DefaultBackground {
  background-color: #e2e2e2;
  height: 7px;
  width: 100%;
  border: none;
  position: relative;
}

.DefaultBackground_background__horizontal {
  height: 7px;
  top: 0;
  left: 0;
  bottom: 4px;
  width: 100%;
}

.rheostat {
  position: relative;
  overflow: visible;
  margin-bottom: 14px;
}

@media (min-width: 1128px) {
  .autoAdjustVerticalPosition {
    top: 12px;
  }
}

.handleContainer {
  height: 7px;
  top: 0;
  left: 0;
  bottom: 4px;
  width: 100%;
  position: absolute;
}

.rheostat_background {
  background-color: #fcfcfc;
  border: 1px solid #d8d8d8;
  position: relative;
}

.rheostat_background__horizontal {
  height: 7px;
  top: 0;
  left: 0;
  bottom: 4px;
  width: 100%;
}

.react-datepicker__triangle {
  display: none;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.react-datepicker__close-icon::after {
  background-color: #d47e5b;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
