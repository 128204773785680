.pac-container {
  width: 100%;
  margin-top: 8px;
  border-radius: 5px;
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
}
.pac-container:after {
  background-image: none !important;
  height: 0px;
}

.pac-item {
  padding: 10px 15px;
  box-sizing: border-box;
  border-color: #dedede;
}

.pac-item,
.pac-item-query {
  color: #4f4f4f;
  cursor: pointer;
}

.pac-item:hover {
  background: #f3f3f3;
}

.pac-icon {
  display: none;
}
